<template>
  <hit-base-input
    :disabled="disabled"
    :inline-input="inlineInput"
    :label="label"
  >
    <div class="grid grid-cols-1">
      <div
        :class="{
          'set-margin': inlineInput,
          'pt-0.5': removePadding,
        }"
      >
        <color-picker
          :pure-color="colourToDisplay"
          :picker-type="pickerType"
          :disable-alpha="true"
          format="hex6"
          @update:pureColor="updateColor"
        />
      </div>
    </div>
  </hit-base-input>
</template>

<script>
import HitBaseInput from './HitBaseInput';
import {ColorPicker} from 'vue3-colorpicker';
import 'vue3-colorpicker/style.css';
import HitInputMixin from '../../../mixins/form/HitInputMixin';
import {ColourUtils} from '../../../utils/colour';

export default {
  name: 'HitInputColourPicker',
  components: {HitBaseInput, ColorPicker},
  mixins: [HitInputMixin],
  props: {
    value: {
      type: String,
      required: true,
    },
    pickerType: {
      type: String,
      required: false,
      default: 'chrome',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    removePadding: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    /**
     * When no colour is passed to the component, we display the default colour
     */
    colourToDisplay() {
      return this.value ? this.value : ColourUtils.defaultColour;
    },
  },

  methods: {
    updateColor(colourHexa) {
      this.fireInputChange(colourHexa.substring(1));
    },
  },
};
</script>

<style scoped lang="scss">
.compensate-input-padding {
  padding-left: 9px;
}

.set-margin {
  margin: 0 9px;
}
</style>
