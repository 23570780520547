import {DataService, useConfigurationStore} from '@hit/base/src';
import {HitUUIDUtils} from '../uuid';
import {HitUtils} from '../hit/HitUtils';
import {HitDateUtils} from '../date/HitDateUtils';

export class HitActionUtils extends HitUtils {
  /**
   * Different status for an action
   */
  static Status = Object.freeze({
    OPENED: 'OPENED',
    ASSIGNED: 'ASSIGNED',
    CLOSED: 'CLOSED',
  });

  /**
   * Different operations that can be registered in the history
   */
  static Operation = Object.freeze({
    CREATE: 'CREATE',
    ASSIGN: 'ASSIGN',
    UNASSIGN: 'UNASSIGN',
    RENAME: 'RENAME',
    ADD_TAG: 'ADD_TAG',
    REMOVE_TAG: 'REMOVE_TAG',
    MESSAGE: 'MESSAGE',
    START: 'START',
    STOP: 'STOP',
    CLOSE: 'CLOSE',
    REOPEN: 'REOPEN',
    CHANGE_DUE_DATE: 'CHANGE_DUE_DATE',
  });

  /**
   * Generates a random number with 6 digits
   */
  static generateRandomNo() {
    return Math.floor(100000 + Math.random() * 900000);
  }

  /**
   * The default due date is the same day. If the user wants another due date,
   * he needs to change the date
   */
  static getDefaultDueDate() {
    return new Date().toISOString();
  }

  /**
   * @param id - ID that we want to use for the new action
   * @param data - Data to initialize some columns of the action during the creation
   */
  static create(id, data) {
    return DataService.create('action', {
      ...data,
      id: id,
      no: this.generateRandomNo(),
      designation: data?.designation ?? 'NEW ACTION',
      status: this.Status.OPENED,
      due_date: this.getDefaultDueDate(),
    }).then(() => {
      return DataService.create('action_history', {
        id: HitUUIDUtils.generate(),
        action_id: id,
        operation: this.Operation.CREATE,
        message: data?.designation ?? 'NEW ACTION',
      });
    });
  }

  /**
   * Converts the duration string returned by the database in milliseconds
   * @param d - duration string returned by the database
   * @returns {number}
   */
  static durationToMs(d) {
    if (!d) return 0;
    const pattern = /(?:(\d+) days )?(?:(\d+):(\d+):(\d+)(?:\.(\d+))?)?/;
    const match = d.match(pattern);
    if (!match) {
      throw new Error('Invalid duration format');
    }
    const day = match[1] ? parseInt(match[1], 10) : 0;
    const h = match[2] ? parseInt(match[2], 10) : 0;
    const m = match[3] ? parseInt(match[3], 10) : 0;
    const s = match[4] ? parseInt(match[4], 10) : 0;
    const sMs = match[5] ? parseInt(match[5], 10) : 0;
    const ms = Math.round(sMs / 1000);
    return (day * 24 * 3600 + h * 3600 + m * 60 + s) * 1000 + ms;
  }

  /**
   * Converts the number of milliseconds into a readable time format
   * @param ms - number of milliseconds
   * @returns {string}
   */
  static formatMs(ms) {
    const locale = useConfigurationStore().userLanguageSnakeCase;
    const h = Math.floor(ms / (1000 * 60 * 60))
      .toString()
      .padStart(2, '0');
    const m = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60))
      .toString()
      .padStart(2, '0');
    const s = Math.round((ms % (1000 * 60)) / 1000)
      .toString()
      .padStart(2, '0');
    switch (locale) {
      case 'en_gb':
        return `${h}:${m}:${s}`;
      default:
        return `${h}h ${m}m ${s}s`;
    }
  }

  /**
   * Converts the duration returned by the database into a readbale time format
   * @param d - duration returned by the database
   * @returns {string}
   */
  static stringifyDuration(d) {
    return this.formatMs(this.durationToMs(d));
  }

  /**
   * Creates the text that will be displayed in the action history
   * @param log - object from the history record
   * @param t - translation function
   * @returns {*}
   */
  static generateHistoryText(log, t) {
    let modifiedParams = undefined;
    if (log.operation === HitActionUtils.Operation.MESSAGE) return log.message;
    if (log.operation === HitActionUtils.Operation.CHANGE_DUE_DATE) {
      modifiedParams = {
        message: HitDateUtils.toShortDate(new Date(log.message)),
      };
    }
    return t(
      `hit-app.action.history-${log.operation
        .toLowerCase()
        .replaceAll('_', '-')}`,
      modifiedParams || log
    );
  }
}
