<template>
  <div>
    <slot
      :handle-change="multiple ? handleChangeItem : handleChangeValue"
      :handle-change-all="handleChangeAllItems"
      :handle-insert="handleInsert"
      :handle-edit="handleEdit"
      :handle-delete="customDeleteFn || handleDelete"
      :handle-change-user-filter="handleChangeUserFilter"
      :user-filters="userFilters"
      :data="multiple ? dataArray : data"
      :attributes="attributes"
      :validation-state="validationState"
      :validations="validations"
      :table-properties="Object.values(tableProperties)"
      :create-item-fn="customCreateItemFn || createItem"
      :id-column="idColumn"
      :set-current-page="setCurrentPage"
      :set-items-per-page="setItemsPerPage"
      :pagination-data="multiple ? paginationData : {}"
      :search-function="searchFunction"
      :move-item="moveItem"
      :sort="customSortFunction"
      :multiple="multiple"
      :fetch-data="useCustomApi ? fetchDataApi : fetchData"
      :ready="ready"
      :route="route"
      :read-only="readOnly"
      :search-field="searchField"
      :create-row-for-dynamic-input-list="createRowForDynamicInputList"
      :delete-row-for-dynamic-input-list="deleteRowsForDynamicInputList"
    />
  </div>
</template>

<script>
import {
  DataService,
  useConfigurationStore,
  useNotificationsStore,
} from '@hit/base';
import {computed, inject, reactive} from 'vue';
import {useVuelidate} from '@vuelidate/core';
import {v4 as uuidv4} from 'uuid';
import {ATTRIBUTE_TYPES, parseDecimal} from '@hit/base';
import {globals} from '@/../../app/src/globals.js';
import Axios from 'axios';
import {translate} from '../../plugins/i18n/i18n';
import {
  filtersToString,
  getColumnNames,
  getFilterNames,
  getJoinedAttributeSyntax,
  mergeFilters,
  OPERATOR_MAPPING,
  queryForComputedColumns,
} from './HitContainerHelpers';

function validations(attributes, configStore) {
  const result = {};
  for (let key in attributes) {
    //TODO take column values into account
    if (attributes[key].dataType === ATTRIBUTE_TYPES.MULTI_LANGUAGE) {
      result[key] = {};
      configStore.companyLanguagesSnakeCase.forEach((locale) => {
        result[key][locale] = attributes[key].validations;
      });
    } else {
      result[key] = attributes[key].validations;
    }
    if (attributes[key].validationGroup) {
      if (!result['$validationGroups']) {
        result['$validationGroups'] = {};
      }
      if (!result['$validationGroups'][attributes[key].validationGroup]) {
        result['$validationGroups'][attributes[key].validationGroup] = [];
      }
      result['$validationGroups'][attributes[key].validationGroup].push(key);
    }
  }
  return result;
}

function getDeepestChildValue(obj) {
  if (obj && typeof obj === 'object' && Object.keys(obj).length > 0) {
    return getDeepestChildValue(obj[Object.keys(obj)[0]]);
  } else {
    return obj;
  }
}

const ITEMS_PER_PAGE_OPTIONS = [
  {
    id: 10,
    label: '10',
  },
  {
    id: 20,
    label: '20',
  },
  {
    id: 50,
    label: '50',
  },
  {
    id: 100,
    label: '100',
  },
];

export default {
  name: 'HitContainer',

  props: {
    filterId: {
      type: String,
      required: false,
    },
    idColumn: {
      type: String,
      required: false,
      default: 'id',
    },
    idColumnIsUuid: {
      type: Boolean,
      required: false,
      default: true,
    },
    filterIdColumn: {
      type: String,
      required: false,
      default: 'id',
    },
    customFilters: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    route: {
      type: String,
      required: true,
    },
    routeIsProcedure: {
      type: Boolean,
      required: false,
      default: false,
    },
    procedureData: {
      type: Object,
      required: false,
      default: () => {},
    },
    attributes: {
      type: Object,
      required: true,
    },
    customCreateItemFn: {
      type: Function,
      default: null,
    },
    customDeleteFn: {
      type: Function,
      default: null,
    },
    customSaveFn: {
      type: Function,
      default: null,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    paginate: {
      type: Boolean,
      required: false,
      default: true,
    },
    defaultSort: {
      type: Object,
      required: false,
      default() {
        return {
          property: null,
          order: null,
        };
      },
    },
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    useCustomApi: {
      type: Boolean,
      required: false,
      default: false,
    },
    customApiCreationMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    customApiUrl: {
      type: String,
      required: false,
      default: '',
    },
    itemsIdNotToBeDeleted: {
      type: Array,
      required: false,
      default: [],
    },
    isFormContainer: {
      type: Boolean,
      default: false,
    },
    redirectToIfEmpty: {
      type: String,
      required: false,
      default: null,
    },
    beforeDeleteFn: {
      type: Function,
      default() {
        return null;
      },
    },
    afterDeleteFn: {
      type: Function,
      default() {
        return null;
      },
    },
    preProcessDataFn: {
      type: Function,
      default: null,
    },
    /**
     * The default ID column is set to ID and is added to the attributes.
     * But in some cases, we do not want to add this column (when doing .sum() for example)
     */
    fetchIdColumn: {
      type: Boolean,
      required: false,
      default: true,
    },

    /**
     * Name of the column the search filter is applied to
     */
    searchField: {
      type: String,
      required: false,
      default: 'search',
    },
  },

  setup(props) {
    const configStore = useConfigurationStore();
    const data = reactive({});
    const rules = computed(() => validations(props.attributes, configStore));
    const validationState = useVuelidate(rules, data);
    return {data, configStore, validationState};
  },

  data() {
    return {
      parentDomWrapper: undefined,
      paginationData: {
        currentPage: 0,
        itemsPerPage: 20,
        noPages: 0,
        itemsPerPageOptions: [...ITEMS_PER_PAGE_OPTIONS],
      },
      search: {
        value: '',
      },
      orderedDataKeys: [], //needed for order, as object doesnt keep insertion order and map doesnt allow changing positions
      customSort: {},
      compareCustomSort: {}, // needed to compare values in deep watcher, since old and new values in deep watchers point to the same object if fields have been changed
      userFilters: undefined,
      ready: false,
      formData: {},
    };
  },

  computed: {
    validations() {
      return validations(this.attributes, this.configStore);
    },
    mainSearchAttribute() {
      const searchAttribute = Object.entries(this.attributes).find(
        ([key, value]) => value?.tableProperties?.mainSearch === true
      );
      if (searchAttribute) {
        const column = searchAttribute[1].column
          ? searchAttribute[1].column
          : searchAttribute[0];
        return [searchAttribute[1], column];
      } else {
        return [null, null];
      }
    },
    attributesToFetch() {
      const result = [];
      for (let attrKey in this.attributes) {
        let attribute = this.attributes[attrKey];
        if (attribute.formLocation) continue; // dont try to fetch form attributes
        if (this.configStore.configuration[`sync.${this.route}.active`])
          attribute.readOnly = true;
        const innerPlaceholder =
          (attribute.tableProperties.mainSearch && this.search.value) ||
          (attribute.dataType === ATTRIBUTE_TYPES.TAGS &&
            Object.keys(this.customFilters).some((key) =>
              key.includes('tag')
            )) ||
          (this.userFilters && attrKey in this.userFilters)
            ? '!inner'
            : '';
        if (
          [ATTRIBUTE_TYPES.JOINED_STRING_COLUMN, ATTRIBUTE_TYPES.TAGS].includes(
            attribute.dataType
          )
        ) {
          result.push(
            `${attrKey}:${getJoinedAttributeSyntax(
              attribute.column,
              innerPlaceholder === '!inner'
            )}`
          );
        } else if (attribute.dataType === ATTRIBUTE_TYPES.COMPUTED_COLUMN) {
          attribute.column.columns.forEach((column) => {
            result.push(column);
          });
        } else if (attribute.dataType === ATTRIBUTE_TYPES.MULTI_LANGUAGE) {
          const prefix = attribute.column ? attribute.column : attrKey;
          for (const locale of this.parsedLocaleKeys()) {
            result.push(prefix + '_' + locale);
          }
        } else if (attribute.dataType === ATTRIBUTE_TYPES.ML_WITH_FALLBACK) {
          if (!attribute.column) {
            result.push(
              `${attrKey}${this.configStore.userLanguagePascalCase}:${attrKey}_${this.configStore.userLanguageSnakeCase}`
            );
            if (
              this.configStore.userLanguagePascalCase !==
              this.configStore.userLanguageSnakeCase
            ) {
              result.push(
                `${attrKey}${this.configStore.mainLanguagePascalCase}:${attrKey}_${this.configStore.mainLanguageSnakeCase}`
              );
            }
          } else {
            result.push(
              `${attrKey}${
                this.configStore.userLanguagePascalCase
              }:${attribute.column.replace(
                'LOCALE',
                this.configStore.userLanguageSnakeCase
              )}`
            );
            if (
              this.configStore.userLanguagePascalCase !==
              this.configStore.userLanguageSnakeCase
            ) {
              result.push(
                `${attrKey}${
                  this.configStore.mainLanguagePascalCase
                }:${attribute.column.replace(
                  'LOCALE',
                  this.configStore.mainLanguageSnakeCase
                )}`
              );
            }
          }
        } else if (attribute.dataType === ATTRIBUTE_TYPES.NOT_FETCHED) {
          /*eslint no-empty: "error"*/
          // These values are not fetched from the backend but generated in the frontend to fill out the columns
        } else {
          result.push(`${attrKey}:` + (attribute.column || attrKey));
        }
      }
      if (!result.includes(this.idColumn)) {
        result.push(this.idColumn);
      }
      if (!result.includes(this.filterIdColumn) && this.fetchIdColumn) {
        result.push(this.filterIdColumn);
      }
      if (this.isFormContainer) {
        result.push('data');
      }
      return this.groupColumnsFromSameTable(result);
    },
    tableProperties() {
      let result = {};
      for (let attrKey in this.attributes) {
        if (
          !this.attributes[attrKey].tableProperties ||
          Object.keys(this.attributes[attrKey].tableProperties).length === 0
        ) {
          continue;
        }
        result[attrKey] = {...this.attributes[attrKey].tableProperties};
        result[attrKey].propertyName = attrKey;
        if (this.attributes[attrKey].forcedType) {
          result[attrKey].type = this.attributes[attrKey].forcedType;
          continue;
        }

        switch (this.attributes[attrKey].dataType) {
          case ATTRIBUTE_TYPES.DATE:
            result[attrKey].type = 'date';
            break;
          case ATTRIBUTE_TYPES.DATETIME:
            result[attrKey].type = 'datetime';
            break;
          case ATTRIBUTE_TYPES.DATETIME_SECONDS:
            result[attrKey].type = 'datetime-seconds';
            break;
          case ATTRIBUTE_TYPES.DECIMAL:
            result[attrKey].type = 'decimal';
            break;
          case ATTRIBUTE_TYPES.INT:
            result[attrKey].type = 'int';
            break;
          case ATTRIBUTE_TYPES.NUMBER:
            result[attrKey].type = 'number';
            break;
          case ATTRIBUTE_TYPES.BOOLEAN:
            result[attrKey].type = 'bool';
            break;
          case ATTRIBUTE_TYPES.COLOUR:
            result[attrKey].type = 'colour';
            break;
          case ATTRIBUTE_TYPES.ENUMERATION:
            result[attrKey].type = 'enum';
            break;
          case ATTRIBUTE_TYPES.TAGS:
            result[attrKey].type = 'tag';
            break;
          case ATTRIBUTE_TYPES.JOINED_STRING_COLUMN:
            result[attrKey].type = 'join';
            break;
          case ATTRIBUTE_TYPES.AGGREGATE_FUNCTION:
            result[attrKey].type = 'function';
            break;
          default:
            result[attrKey].type = 'standard';
            break;
        }
      }
      return result;
    },
    usePagination() {
      return this.multiple && this.paginate;
    },
    paginationOffset() {
      return this.paginationData.itemsPerPage * this.paginationData.currentPage;
    },
    postgRESTOrderString() {
      if (!this.multiple) {
        return null;
      }
      let property, order;
      if (this.customSort.property && this.customSort.order) {
        property = this.customSort.property;
        order = this.customSort.order;
      } else if (this.defaultSort.property && this.defaultSort.order) {
        property = this.defaultSort.property;
        order = this.defaultSort.order;
      }
      if (!property || !order) {
        return null;
      }
      if (this.attributes[property] && this.attributes[property].column) {
        property = this.attributes[property].column;
      }
      return property + '.' + order;
    },
    postgRESTFilters() {
      let result = {};
      if (this.filterId) {
        result['and'] = [`${this.filterIdColumn}.eq.${this.filterId}`];
      }
      if (this.onlyLoadActiveRecords) result['and'] = ['active.is.true'];
      return filtersToString(
        mergeFilters([
          result,
          this.customFilters,
          this.searchFilters(),
          this.userFiltersToPostgRESTFilters(),
        ])
      );
    },
    dataArray() {
      return this.orderedDataKeys.map((key) => this.data[key]);
    },
  },

  watch: {
    filterId: {
      handler(newId, oldId) {
        if (oldId !== newId || (!oldId && !newId)) {
          if (this.useCustomApi) {
            this.fetchDataApi();
          } else {
            this.fetchData();
          }
        }
      },
    },
    customFilters: {
      handler(newFilter, oldFilter) {
        if (this.useCustomApi) {
          this.fetchDataApi();
        } else {
          this.fetchData();
        }
      },
      deep: true,
    },
    customSort: {
      handler() {
        if (
          this.customSort.property &&
          (this.customSort.property !== this.compareCustomSort.property ||
            this.customSort.order !== this.compareCustomSort.order)
        ) {
          if (this.useCustomApi) {
            this.fetchDataApi();
          } else {
            this.fetchData();
          }
        }
      },
      deep: true,
    },
    'paginationData.currentPage'(newValue) {
      //handle delete depends on the watcher
      if (this.useCustomApi) {
        this.fetchDataApi();
      } else {
        this.fetchData();
      }
    },
    'paginationData.itemsPerPage'(newValue) {
      if (this.useCustomApi) {
        this.fetchDataApi();
      } else {
        this.fetchData();
      }
    },
    'search.value'(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setCurrentPage(0);
        if (this.useCustomApi) {
          this.fetchDataApi();
        } else {
          this.fetchData();
        }
      }
    },
    attributes(newValue, oldValue) {
      this.loadAttributeValuesFromFormData();
    },
    userFilters: {
      handler() {
        if (this.useCustomApi) {
          this.fetchDataApi();
        } else {
          this.fetchData();
        }
      },
      deep: true,
    },
  },

  async beforeMount() {
    this.initData();
    const parentPanel = inject('panelId', null);
    const parentModal = inject('modalId', null);
    this.parentDomWrapper = parentModal || parentPanel;
    this.userFilters = useConfigurationStore().getTableFilters(
      this.parentDomWrapper
    );
  },

  methods: {
    /**
     * Initialises the data variable with the correct data structure
     */
    initData() {
      if (!this.multiple) {
        for (let key in this.attributes) {
          this.data[key] = this.attributes[key].getInitialValue();
        }
      }
    },
    async fetchData() {
      const setup = {
        attributes: this.attributesToFetch.join(), // all "column" entries separated by commas
        filters: this.postgRESTFilters,
        getCount: this.usePagination,
        offset: this.usePagination ? this.paginationOffset : null,
        limit: this.usePagination ? this.paginationData.itemsPerPage : null,
        order: this.postgRESTOrderString,
      };
      if (!this.routeIsProcedure) {
        return DataService.read(this.route, setup)
          .then((response) => {
            if (response && response.data) {
              this.processResponse(response.data, response.headers);
            }
          })
          .catch((error) => {
            this.handleFetchError(error);
          });
      } else {
        return DataService.procedure(this.route, this.procedureData, setup)
          .then((response) => {
            if (response && response.data) {
              this.processResponse(response.data, response.headers);
            }
          })
          .catch((error) => {
            this.handleFetchError(error);
          });
      }
    },
    processResponse(data, headers) {
      if (this.redirectToIfEmpty && data.length === 0) {
        useNotificationsStore().insertBannerNotification(
          translate('hit-base.banners.record-is-empty')
        );
        this.$router.push({name: this.redirectToIfEmpty});
      }
      //TODO test performance for large amount of items + make more efficient
      if (this.multiple) {
        for (let key in this.data) {
          delete this.data[key];
        }
        for (let item of data) {
          this.data[item[this.idColumn]] = this.dbItemToLocalItem(item);
        }
        if (this.preProcessDataFn) this.data = this.preProcessDataFn(this.data);
        this.orderedDataKeys.length = 0;
        Object.keys(this.data).forEach((k) => this.orderedDataKeys.push(k));
        if (headers && headers['content-range']) {
          this.paginationData.noPages = Math.ceil(
            parseInt(headers['content-range'].split('/')[1]) /
              this.paginationData.itemsPerPage
          );
        }
      } else if (Array.isArray(data) && data.length === 1) {
        Object.assign(this.data, this.dbItemToLocalItem(data[0]));
        if (this.isFormContainer) {
          Object.assign(this.formData, data[0]['data']);
          this.$emit('form-data-loaded', this.formData, () => {
            return this.validationState;
          });
        }
      }
      this.ready = true;
      this.$emit('finished-loading', this.data);
    },
    handleFetchError(error) {
      if (error.response && error.response.status === 416) {
        this.setCurrentPage(0);
      } else {
        throw Error;
      }
    },
    fetchDataApi() {
      if (!this.customApiCreationMode) {
        let config = {
          headers: {
            Authorization: 'Bearer ' + globals.$keycloak.token,
          },
          params: {},
        };

        if (
          this.multiple &&
          this.search.value &&
          this.search.value.trim() !== ''
        ) {
          config.params = {
            searchValue: this.search.value,
          };
        }

        if (this.filterId) {
          config.params['filterId'] = this.filterId;
        }

        Axios.get(this.customApiUrl + this.route + '/list', config)
          .then((response) => {
            //TODO test performance for large amount of items
            if (this.multiple) {
              //TODO make more efficient
              for (let key in this.data) {
                delete this.data[key];
              }
              this.orderedDataKeys.length = 0;
              for (let item of response.data) {
                this.data[item[this.idColumn]] = this.dbItemToLocalItem(item);
                this.orderedDataKeys.push(item[this.idColumn]);
              }
              if (
                response &&
                response.headers &&
                response.headers['content-range']
              ) {
                this.paginationData.noPages = Math.ceil(
                  parseInt(response.headers['content-range'].split('/')[1]) /
                    this.paginationData.itemsPerPage
                );
              }
            } else if (
              Array.isArray(response.data) &&
              response.data.length === 1
            ) {
              const formatted_response = this.snakeToCamel(response.data[0]);
              Object.assign(
                this.data,
                this.dbItemToLocalItem(formatted_response)
              );
            } else {
              //TODO error handling
            }
            this.ready = true;
          })
          .catch((error) => {
            // handle error
            console.error(error);
            //todo display error
          });
      }
    },
    handleChangeItem(item) {
      if (this.customSaveFn) {
        this.customSaveFn(item);
        return;
      }
      if (this.readOnly) {
        return;
      }
      if (!this.multiple) {
        console.error('operation only works for lists');
      }
      this.parseValues(item);
      this.data[item[this.idColumn]] = item;
      //TODO poc handle update failure
      this.updateDBItem(this.filterEmbeddedResources(item));
      this.$emit('data-changed', item);
    },
    handleChangeAllItems(keyValuePairs) {
      if (!this.multiple) {
        console.error('operation only works for lists');
      }
      for (let dataKey in this.data) {
        let item = this.data[dataKey];
        for (let key in keyValuePairs) {
          item[key] = keyValuePairs[key];
        }
      }
      this.updateDBItems(keyValuePairs);
    },
    handleChangeValue(key, newValue) {
      if (this.readOnly) {
        return;
      }
      if (this.multiple) {
        console.error("operation doesn't work for lists");
        return;
      }
      if (
        this.attributes[key].dataType === ATTRIBUTE_TYPES.DATE ||
        this.attributes[key].dataType === ATTRIBUTE_TYPES.DATETIME ||
        this.attributes[key].dataType === ATTRIBUTE_TYPES.DATETIME_SECONDS
      ) {
        // for html date inputs, the change event is already triggered on input. This is a problem when entering a date
        // starting with 0, as a change event with null is triggered.
        if (newValue === null) {
          return;
        }
      }
      if (this.attributes[key].dataType === ATTRIBUTE_TYPES.MULTI_LANGUAGE) {
        this.handleChangeValueMultiLanguage(key, newValue);
      } else {
        this.handleChangeValueDefault(key, newValue);
      }
      this.$emit('data-changed', {[key]: newValue});
    },
    handleChangeValueMultiLanguage(key, languageValues) {
      const columnValue = this.attributes[key].column;
      const prefix = columnValue ? columnValue : key;
      for (const languageId in languageValues) {
        if (languageValues[languageId] !== this.data[key][languageId]) {
          this.data[key][languageId] = languageValues[languageId];
          this.updateDBValue(
            prefix + '_' + languageId,
            this.data[key][languageId]
          );
        }
      }
    },
    handleChangeValueDefault(key, newValue) {
      let value;
      if (this.attributes[key].dataType === ATTRIBUTE_TYPES.DECIMAL) {
        value = parseDecimal(
          newValue,
          this.attributes[key].decimalPrecision,
          this.attributes[key].decimalDefaultValue
        );
      } else if (
        this.attributes[key].dataType === ATTRIBUTE_TYPES.DATETIME ||
        this.attributes[key].dataType === ATTRIBUTE_TYPES.DATETIME_SECONDS
      ) {
        value = newValue === 'EMPTY_DATE' ? null : new Date(newValue);
      } else if (this.attributes[key].dataType === ATTRIBUTE_TYPES.DATE) {
        value =
          newValue === 'EMPTY_DATE'
            ? null
            : new Date(newValue).toISOString().split('T')[0];
      } else {
        value = newValue;
      }
      //do nothing if value didn't change
      if (this.data[key] === newValue) {
        return;
      }
      //TODO poc handle update failure
      this.data[key] = value;
      //dont update db for embedded resources but it is supported for the type FK_JOINED_OBJECT
      if (
        !this.attributes[key].dataType === ATTRIBUTE_TYPES.FK_JOINED_OBJECT &&
        this.attributes[key].column &&
        this.attributes[key].column.includes('(')
      ) {
        return;
      }
      if (
        this.validationState.$errors.some((error) => error.$property === key)
      ) {
        return;
      }
      if (this.attributes[key].formLocation) {
        this.updateFormData(this.attributes[key].formLocation, this.data[key]);
        this.updateDBValue('data', this.formData);
      } else {
        this.updateDBValue(
          this.attributes[key].fkToUpdate || this.attributes[key].column || key,
          this.data[key]
        ).then(() => {
          if (
            this.attributes[key].dataType === ATTRIBUTE_TYPES.FK_JOINED_OBJECT
          ) {
            this.fetchData();
          }
        });
      }
    },
    handleInsert(item) {
      if (this.readOnly) {
        return;
      }
      if (!this.multiple) {
        console.error('operation only works for lists');
        return;
      }
      this.parseValues(item);
      this.data[item[this.idColumn]] = item;
      this.orderedDataKeys.push(item[this.idColumn]);
      //TODO poc handle update failure
      this.upsertIntoDB(this.filterEmbeddedResources(item));
      this.$emit('data-changed');
    },
    async handleDelete(items) {
      if (this.readOnly) {
        return;
      }
      if (!this.multiple) {
        console.error('operation only works for lists');
        return;
      }
      try {
        const itemsToDelete = items.filter(
          (x) => !this.itemsIdNotToBeDeleted.includes(x[this.idColumn])
        );
        if (this.beforeDeleteFn) {
          await this.beforeDeleteFn(itemsToDelete);
        }
        if (this.useCustomApi) {
          await this.deleteFromDbCustomApi(itemsToDelete);
        } else {
          await this.deleteFromDB(itemsToDelete);
        }
        itemsToDelete.forEach((item) => {
          let itemId = item[this.idColumn];
          delete this.data[itemId];
          let dataKeyIndex = this.orderedDataKeys.indexOf(itemId);
          if (dataKeyIndex > -1) {
            this.orderedDataKeys.splice(dataKeyIndex, 1);
          }
        });

        let displayCannotDeleteMessageError = false;
        items.forEach((item) => {
          let itemId = item[this.idColumn];
          if (this.itemsIdNotToBeDeleted.includes(itemId)) {
            displayCannotDeleteMessageError = true;
          }
        });

        if (displayCannotDeleteMessageError) {
          useNotificationsStore().insertBannerNotification(
            translate('hit-base.banners.cannot-delete-some-record')
          );
        }
        if (this.afterDeleteFn) {
          this.afterDeleteFn();
        }
        //if deleting all items from the last page, current page is one over the maximum
        if (
          this.paginationData.currentPage === this.paginationData.noPages - 1 &&
          this.orderedDataKeys.length === 0
        ) {
          //triggers fetch data because of watcher
          this.paginationData.currentPage = Math.max(
            this.paginationData.noPages - 2,
            0
          );
        } else {
          if (this.useCustomApi) {
            this.fetchDataApi();
          } else {
            this.fetchData();
          }
        }
        this.$emit('data-changed');
        this.$emit('data-deleted');
      } catch (e) {
        //TODO proper error handling once an error system is in place
        console.error(e);
        if (e.response && e.response.status) {
          window.alert('delete failed with status ' + e.response.status);
        }
        if (this.useCustomApi) {
          this.fetchDataApi();
        } else {
          this.fetchData();
        }
      }
    },
    handleEdit(itemId) {
      this.$emit('editItem', itemId);
    },
    snakeToCamel(obj) {
      return Object.keys(obj).reduce((camelObj, key) => {
        const camelKey = key.replace(/_([a-z])/g, (match, p1) =>
          p1.toUpperCase()
        );
        camelObj[camelKey] = obj[key];
        return camelObj;
      }, {});
    },
    dbItemToLocalItem(item) {
      let localItem = {};
      for (let attrKey in this.attributes) {
        let attribute = this.attributes[attrKey];
        if (attribute.dataType === ATTRIBUTE_TYPES.MULTI_LANGUAGE) {
          if (!localItem[attrKey]) {
            localItem[attrKey] = {};
          }
          const column = this.attributes[attrKey].column;
          const prefix = column ? column : attrKey;
          for (const locale of this.parsedLocaleKeys()) {
            localItem[attrKey][locale] = item[prefix + '_' + locale];
          }
          continue;
        }
        if (attribute.dataType === ATTRIBUTE_TYPES.COMPUTED_COLUMN) {
          const objectWithColumns = {};
          attribute.column.columns.forEach((column) => {
            objectWithColumns[column] = item[column];
          });
          localItem[attrKey] = attribute.transform(objectWithColumns);
        } else if (attribute.transform && item[attrKey]) {
          localItem[attrKey] = attribute.transform(item[attrKey]);
        } else {
          localItem[attrKey] = item[attrKey];
        }
        if (attribute.dataType === ATTRIBUTE_TYPES.DECIMAL) {
          localItem[attrKey] = parseDecimal(
            localItem[attrKey],
            attribute.decimalPrecision,
            attribute.decimalDefaultValue
          );
        } else if (
          attribute.dataType === ATTRIBUTE_TYPES.DATE ||
          attribute.dataType === ATTRIBUTE_TYPES.DATETIME ||
          attribute.dataType === ATTRIBUTE_TYPES.DATETIME_SECONDS
        ) {
          localItem[attrKey] = new Date(item[attrKey]);
        } else if (attribute.dataType === ATTRIBUTE_TYPES.ML_WITH_FALLBACK) {
          const userLanguageValue = getDeepestChildValue(
            item[attrKey + this.configStore.userLanguagePascalCase]
          );
          const mainLanguageValue = getDeepestChildValue(
            item[attrKey + this.configStore.mainLanguagePascalCase]
          );
          localItem[attrKey] = userLanguageValue
            ? userLanguageValue
            : mainLanguageValue;
        }
      }
      localItem[this.idColumn] = item[this.idColumn];
      localItem[this.filterIdColumn] = item[this.filterIdColumn];
      return localItem;
    },
    localItemToDbItem(item) {
      let dbItem = {};
      for (let key in item) {
        //skip fields that arent in attributes
        if (
          !this.attributes[key] &&
          key !== this.idColumn &&
          key !== this.filterIdColumn
        ) {
          continue;
        }
        //dont update readonly fields
        if (this.attributes[key] && this.attributes[key].readOnly) {
          continue;
        }
        let dbCol = key;
        if (this.attributes[key] && this.attributes[key].column) {
          dbCol = this.attributes[key].column;
        }
        dbItem[dbCol] = item[key];
      }
      return dbItem;
    },
    updateDBItem(item) {
      if (!this.multiple) {
        console.error('operation only works for lists');
        return;
      }
      DataService.update(
        this.route,
        {[this.idColumn]: 'eq.' + item[this.idColumn]},
        this.localItemToDbItem(item)
      ).then(() => {
        this.fetchData();
        this.$emit('data-updated');
      });
    },
    updateDBItems(keyValuePairs) {
      if (!this.multiple) {
        console.error('operation only works for lists');
        return;
      }
      DataService.update(
        this.route,
        this.postgRESTFilters,
        this.localItemToDbItem(keyValuePairs)
      ).then(() => {
        this.fetchData();
        this.$emit('data-updated');
      });
    },
    updateDBValue(key, newValue) {
      return DataService.update(
        this.route,
        {[this.idColumn]: 'eq.' + this.filterId},
        {[key]: newValue}
      ).then(() => this.$emit('data-updated'));
    },
    updateFormData(formLocation, newValue) {
      const locationKeys = formLocation.split('.');
      let subObj = this.formData;
      for (const locationKey of locationKeys.slice(0, -1)) {
        subObj = subObj[locationKey];
      }
      subObj[locationKeys.slice(-1)[0]] = newValue;
    },
    insertInputListRow(formLocation, row) {
      const locationKeys = formLocation.split('.');
      let subObj = this.formData;
      for (const locationKey of locationKeys.slice(0, -1)) {
        subObj = subObj[locationKey];
      }
      if (Array.isArray(subObj[locationKeys.slice(-1)[0]])) {
        subObj[locationKeys.slice(-1)[0]].push(row);
      }
    },
    upsertIntoDB(item) {
      DataService.upsert(this.route, this.localItemToDbItem(item)).then(() => {
        this.fetchData();
        this.$emit('data-updated');
      });
    },
    async deleteFromDB(items) {
      await DataService.delete(this.route, {
        [this.idColumn]:
          'in.(' + items.map((x) => x[this.idColumn]).join() + ')',
      });
      this.$emit('data-deleted');
    },
    async deleteFromDbCustomApi(items) {
      try {
        for (const item of items) {
          await Axios.delete(this.customApiUrl + this.route + `/delete`, {
            params: {
              id: item.id,
            },
            headers: {
              Authorization: 'Bearer ' + globals.$keycloak.token,
            },
          });
        }
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de la suppression des éléments :",
          error
        );
      }
    },
    createItem() {
      let newItem = {};
      for (let attrKey in this.attributes) {
        let attribute = this.attributes[attrKey];
        newItem[attrKey] = attribute.getInitialValue();
      }
      if (this.idColumnIsUuid) {
        newItem[this.idColumn] = uuidv4();
      }
      if (this.filterId) {
        newItem[this.filterIdColumn] = this.filterId;
      }
      return newItem;
    },
    setCurrentPage(page) {
      this.paginationData.currentPage = Math.max(page, 0);
    },
    setItemsPerPage(n) {
      this.paginationData.itemsPerPage = n;
      this.paginationData.currentPage = 0;
    },
    searchFunction(searchValue) {
      this.search.value = searchValue;
    },
    searchFilters() {
      if (!this.search?.value || !this.searchField) return {};
      const filterParts = this.search.value.split(' ');
      let filterString = '';
      filterParts.forEach((subString) => {
        filterString += `${this.searchField}.ilike.*${subString}*,`;
      });
      return {and: filterString.slice(0, -1)};
    },

    /**
     * Updated filters are stored in the config table to keep the filters state
     */
    handleChangeUserFilter(filters) {
      Object.keys(this.userFilters).forEach(
        (key) => delete this.userFilters[key]
      );
      this.userFilters = {};
      Object.assign(this.userFilters, filters);
      if (this.parentDomWrapper) {
        this.configStore.updateTableFilters(
          this.parentDomWrapper,
          this.userFilters
        );
      }
    },

    parsedLocaleKeys() {
      return this.configStore.companyLanguagesSnakeCase.map((locale) =>
        locale.replace('-', '_').toLowerCase()
      );
    },
    groupColumnsFromSameTable(initialArray) {
      const groupedColumns = {};
      initialArray.forEach((item) => {
        const match = item.match(/^(.+)\((.+)\)$/);
        if (match) {
          const [, table, columnName] = match;
          if (!groupedColumns[table]) {
            groupedColumns[table] = [];
          }
          groupedColumns[table].push(columnName);
        } else {
          if (!groupedColumns['default']) {
            groupedColumns['default'] = [];
          }
          groupedColumns['default'].push(item);
        }
      });
      return Object.keys(groupedColumns).flatMap((functionName) => {
        const columns = groupedColumns[functionName];
        if (functionName === 'default') {
          return columns;
        } else {
          return [`${functionName}(${columns.join(',')})`];
        }
      });
    },
    filterEmbeddedResources(item) {
      let result = {};
      Object.keys(item).forEach((key) => {
        //ignore embedded resources
        if (
          this.attributes[key] &&
          this.attributes[key].column &&
          (typeof this.attributes[key].column === 'object' ||
            this.attributes[key].column.includes('('))
        ) {
          return;
        }
        result[key] = item[key];
      });
      return result;
    },
    parseValues(item) {
      Object.keys(item).forEach((key) => {
        if (!this.attributes[key]) {
          return;
        }
        if (this.attributes[key].dataType === ATTRIBUTE_TYPES.DECIMAL) {
          item[key] = parseDecimal(
            item[key],
            this.attributes[key].decimalPrecision
          );
        } else if (
          this.attributes[key].dataType === ATTRIBUTE_TYPES.DATE ||
          this.attributes[key].dataType === ATTRIBUTE_TYPES.DATETIME ||
          this.attributes[key].dataType === ATTRIBUTE_TYPES.DATETIME_SECONDS
        ) {
          item[key] = new Date(item[key]);
        }
      });
    },
    moveItem(draggedItem, droppedOffAtItem) {
      let masterSort = this.customSort.property
        ? {...this.customSort}
        : {...this.defaultSort};
      if (masterSort.property !== 'sort_order') {
        throw Error(
          'The data needs to be sorted via the property sort_order to be draggable'
        );
      }
      let draggedItemIndex = 0;
      let droppedOffAtItemIndex = 0;
      while (
        draggedItemIndex < this.orderedDataKeys.length &&
        draggedItem[this.idColumn] !== this.orderedDataKeys[draggedItemIndex]
      ) {
        draggedItemIndex++;
      }
      while (
        droppedOffAtItemIndex < this.orderedDataKeys.length &&
        droppedOffAtItem[this.idColumn] !==
          this.orderedDataKeys[droppedOffAtItemIndex]
      ) {
        droppedOffAtItemIndex++;
      }
      if (
        draggedItemIndex >= this.orderedDataKeys.length ||
        droppedOffAtItemIndex >= this.orderedDataKeys.length ||
        draggedItemIndex === droppedOffAtItemIndex
      ) {
        return;
      }
      let droppedItemId;
      let previousItemId;
      let nexItemId;
      if (draggedItemIndex > droppedOffAtItemIndex) {
        droppedItemId = this.orderedDataKeys[draggedItemIndex];
        previousItemId =
          droppedOffAtItemIndex > 0
            ? this.orderedDataKeys[droppedOffAtItemIndex - 1]
            : null;
        nexItemId = this.orderedDataKeys[droppedOffAtItemIndex];
        this.orderedDataKeys.splice(
          droppedOffAtItemIndex,
          0,
          this.orderedDataKeys[draggedItemIndex]
        );
        this.orderedDataKeys.splice(draggedItemIndex + 1, 1);
      } else {
        droppedItemId = this.orderedDataKeys[draggedItemIndex];
        nexItemId =
          droppedOffAtItemIndex < this.orderedDataKeys.length - 1
            ? this.orderedDataKeys[droppedOffAtItemIndex + 1]
            : null;
        previousItemId = this.orderedDataKeys[droppedOffAtItemIndex];
        this.orderedDataKeys.splice(
          droppedOffAtItemIndex + 1,
          0,
          this.orderedDataKeys[draggedItemIndex]
        );
        this.orderedDataKeys.splice(draggedItemIndex, 1);
      }
      if (masterSort.order === 'asc') {
        this.moveItemsDB([droppedItemId], previousItemId, nexItemId);
      } else {
        this.moveItemsDB([droppedItemId], nexItemId, previousItemId);
      }
    },
    moveItemsDB(droppedItemsIds, previousItemId, nexItemId) {
      DataService.procedure('insert_items_between', {
        previous_item: previousItemId,
        next_item: nexItemId,
        insert_items: droppedItemsIds,
        table_name: this.route,
        fk_column_name: this.filterIdColumn,
      }).then(() => {
        this.fetchData();
      });
    },
    customSortFunction(newValue) {
      this.compareCustomSort.property = this.customSort.property;
      this.compareCustomSort.order = this.customSort.order;
      if (newValue && newValue.property && newValue.property.propertyName) {
        this.customSort.property = newValue.property.propertyName;
        this.customSort.order = newValue.asc ? 'asc' : 'desc';
      } else {
        this.customSort.property = null;
        this.customSort.order = null;
      }
    },
    loadAttributeValuesFromFormData() {
      if (this.isFormContainer && this.formData) {
        for (const attributeId in this.attributes) {
          let attribute = this.attributes[attributeId];
          if (attribute.formLocation) {
            const locationKeys = attribute.formLocation.split('.');
            // locationKeys is usually [sectionId, 'content', inputId, 'data']
            // value would then be this.formData.sections[sectionId]['content'][inputId]['data']
            const value = locationKeys.reduce(
              (acc, key) => acc && acc[key],
              this.formData
            );
            this.data[attributeId] = value;
          }
        }
      }
    },
    userFiltersToPostgRESTFilters() {
      if (!this.userFilters) return {};
      const pgrstFilters = {};
      Object.entries(this.userFilters).forEach(([key, filters]) => {
        const att = this.attributes[key];
        const filterNames = getFilterNames(att, key);
        const columnNames = getColumnNames(att, key);
        filterNames.forEach((filterName) => {
          if (!(filterName in pgrstFilters)) pgrstFilters[filterName] = [];
          if ([ATTRIBUTE_TYPES.COMPUTED_COLUMN].includes(att.dataType)) {
            const query = queryForComputedColumns(columnNames, filters);
            pgrstFilters[filterName].push(query);
          } else {
            columnNames.forEach((columnName) => {
              Object.entries(filters).forEach(([filter, fValues]) => {
                if (!fValues && !(att.dataType === ATTRIBUTE_TYPES.BOOLEAN))
                  return;
                if (['>', '<', '≥', '≤', '='].includes(filter)) {
                  const query = `${columnName}.${OPERATOR_MAPPING[filter]}.${fValues}`;
                  pgrstFilters[filterName].push(query);
                } else {
                  fValues.forEach((val) => {
                    if (!val) return;
                    const query =
                      filter === '≠'
                        ? `${columnName}.${OPERATOR_MAPPING[filter]}.${val}`
                        : `${columnName}.${OPERATOR_MAPPING[filter]}.*${val}*`;
                    pgrstFilters[filterName].push(query);
                  });
                }
              });
            });
          }
        });
      });
      return pgrstFilters;
    },
    createRowForDynamicInputList(properties, formLocation) {
      let row = {};
      row['$id'] = uuidv4();
      for (const prop of properties) {
        row[prop.propertyName] = null;
      }
      this.insertInputListRow(formLocation, row);
      this.updateDBValue('data', this.formData);
    },
    deleteRowsForDynamicInputList(items, formLocation) {
      const locationKeys = formLocation.split('.');
      let subObj = this.formData;
      for (const locationKey of locationKeys.slice(0, -1)) {
        subObj = subObj[locationKey];
      }
      let dataList = subObj[locationKeys.slice(-1)[0]];
      let idList = items.map((item) => item.$id);
      for (let rowIndex = dataList.length - 1; rowIndex >= 0; rowIndex--) {
        if (idList.includes(dataList[rowIndex]['$id'])) {
          dataList.splice(rowIndex, 1);
        }
      }
      this.updateDBValue('data', this.formData);
    },
  },
};
</script>
